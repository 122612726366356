<template>
  <div class="task-row" :class="{ history: canShowHistory }">
    <div v-if="canShowHistory">
      <div>
        <Button @click="$emit('historyExpanded')">
          <Icon :name="historyExpanded ? 'chevron-down' : 'chevron-right'" />
        </Button>
      </div>
    </div>
    <ReferenceList
      :invention="{ id: task.invention._id, ...task.invention }"
      :show-navigation="true"
      :edit-mode="editable && editMode"
      @references:updated="onReferenceUpdated"
    />

    <div>
      <div style="color: var(--theme-primary); font-weight: 600">
        {{ task.milestone?.title || 'No Milestone' }}
      </div>
      <div>
        {{ task.task.title }}
      </div>
    </div>

    <username-list :value="task.task.assignees" />
    <locale-due-at :due-at="task.task.updatedAt || task.task.createdAt" :include-time="true" />
    <locale-due-at :due-at="task.task.dueAt" emtpy-due-at-text="no due at" />
  </div>
  <div v-if="historyExpanded">
    <PersonalHistory :task-id="task.task.id" :person="persons" :from="from" :to="to" />
  </div>
</template>
<script>
import LocaleDueAt from './LocaleDueAt.vue';
import UsernameList from './UsernameList.vue';
import ReferenceList from '@/components/common/ReferenceList';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';

import PersonalHistory from './PersonalHistory.vue';

export default {
  components: {
    LocaleDueAt,
    UsernameList,
    ReferenceList,
    Icon,
    Button,
    PersonalHistory
  },
  props: {
    task: {
      type: Object,
      required: true
    },
    canShowHistory: {
      type: Boolean,
      default: false
    },
    persons: {
      type: Array,
      default: () => []
    },
    from: {
      type: Date,
      required: true
    },
    to: {
      type: Date,
      required: true
    },
    historyExpanded: {
      type: Boolean,
      default: false
    }
  },
  emits: ['historyExpanded'],
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    harrityReference() {
      const reference = this.task.invention.references.find(r => r.toLowerCase().startsWith('harrity'));
      return reference && reference.replace('HARRITY:', '');
    }
  }
};
</script>
<style lang="scss" scoped>
.task-row {
  display: grid;
  grid-gap: 10px;
  border-bottom: 1px solid var(--theme-highlight);
  background: var(--theme-background);
  align-content: center;
  align-items: center;
  min-height: 4em;
  padding: 5px;
  grid-template-columns: minmax(250px, 0.2fr) 3fr 1fr 100px 100px;
  &.history {
    grid-template-columns: 50px minmax(250px, 0.2fr) 3fr 1fr 100px 100px;
  }
}
</style>
