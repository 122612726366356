<template>
  <hub-report />
</template>

<script>
import Report from '@/components/reports/TasksReport.vue';

export default {
  components: {
    'hub-report': Report
  },
  mounted() {}
};
</script>
