<template>
  <div>
    <div :class="checkDueAt(dueAt)">{{ dueAt ? new Date(dueAt).toLocaleDateString() : emtpyDueAtText }}</div>
    <div v-if="includeTime">{{ dueAt ? new Date(dueAt).toLocaleTimeString() : '' }}</div>
  </div>
</template>
<script>
import { differenceInHours, endOfDay } from 'date-fns';

export default {
  props: {
    dueAt: {
      type: [String, Date],
      default: null
    },
    emtpyDueAtText: {
      type: String,
      default: ''
    },
    colorCodingEnabled: {
      type: Boolean,
      default: false
    },
    includeTime: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    checkDueAt(val) {
      if (!this.colorCodingEnabled || !val) {
        return 'no-date';
      }

      const difference = differenceInHours(endOfDay(new Date(val)), new Date());
      if (difference <= 24) {
        return 'danger';
      }

      if (difference <= 96 && difference > 24) {
        return 'warning';
      }

      if (difference > 96) {
        return 'white';
      }

      return 'warning';
    }
  }
};
</script>
<style scoped>
.warning {
  color: var(--theme-warning);
}

.danger {
  color: var(--theme-error);
}

.default {
  color: var(--theme-on-background-accent);
}

.white {
  color: var(--theme-on-surface);
}

.no-date {
  font-style: italic;
}
</style>
