<template>
  <div class="report-wrapper">
    <div class="header">
      <div class="filters">
        <div class="assignees">
          <assignees :value="assignees" label="Assignees" :disabled="loading" @change="asssigneesChanged" />
          <Button class="robot-icon" type="icon" :disabled="!canAddBots || loading" title="Include all bots" @click.stop="addAllBots"
            ><hub-icon name="robot"
          /></Button>
        </div>

        <Multiselect
          v-model:value="aggregationField"
          label="What changed?"
          :options="['status', 'any field']"
          :can-clear="false"
          @change="fieldChanged"
        />
        <date-field
          :value="from"
          label="From"
          :calendar-avaliable="false"
          :disabled="loading"
          :limits="{ days: { max: '0' } }"
          @update:value="fromChanged"
        />
        <date-field
          :value="to"
          label="To"
          :calendar-avaliable="false"
          :disabled="loading"
          :limits="{ days: { max: '0' } }"
          @update:value="toChanged"
        />
        <Button class="reset-button" type="text" :disabled="loading" @click.stop="reset">Reset</Button>
      </div>
    </div>
    <div class="content">
      <template v-if="!isGetCollectionRequestPending">
        <template v-if="aggregationField === 'status'">
          <div v-if="collection && collection.length && assignees?.length">
            <div v-for="entry in sortedCollection" :key="entry._id" class="group">
              <div class="row" @click="toggleExpanded(entry._id)">
                <hub-icon :name="expanded.includes(entry._id) ? 'chevron-up' : 'chevron-down'" />
                <span v-if="entry._id === 'created_milestones'">
                  Milestone{{ entry.tasks.length > 1 ? 's' : '' }} created ({{ entry.tasks.length }})</span
                >
                <span v-else-if="entry._id === 'created_tasks'"> Task{{ entry.tasks.length > 1 ? 's' : '' }} created ({{ entry.tasks.length }})</span>
                <span v-else>
                  <b>{{ entry._id }}</b> ({{ entry.tasks.length }})
                </span>
              </div>
              <div v-if="expanded.includes(entry._id)" class="expanded-report-row">
                <div v-if="entry._id !== 'created_milestones'" class="task-headers">
                  <div></div>
                  <div></div>
                  <div>Assignees</div>
                  <template v-if="aggregationField === 'status'">
                    <div v-if="entry._id !== 'created_tasks'">Task moved to "{{ entry._id }}"</div>
                    <div v-else>Task created</div>
                  </template>
                  <template v-else>
                    <div>Status</div>
                  </template>
                  <div>Task due</div>
                </div>

                <div v-for="task in entry.tasks" :key="task.id">
                  <template v-if="entry._id === 'created_milestones'">
                    <milestone-row :milestone="task" />
                  </template>
                  <template v-else>
                    <task-row
                      :task="task"
                      :can-show-history="true"
                      :persons="assignees"
                      :from="from"
                      :to="to"
                      :history-expanded="expandedHistory === task.task.id"
                      @historyExpanded="expandedHistory = expandedHistory === task.task.id ? null : task.task.id"
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="loading-wrapper">No activities</div>
        </template>
        <template v-else>
          <div class="task-headers">
            <div></div>
            <div></div>
            <div>Assignees</div>
            <div>Updated at</div>
            <div>Task due</div>
          </div>

          <div v-for="(task, index) in collection" :key="task.id">
            <task-row
              :task="task"
              :can-show-history="true"
              :persons="assignees"
              :from="from"
              :to="to"
              :history-expanded="expandedHistory === `${task.task.id}_${index}`"
              @historyExpanded="expandedHistory = expandedHistory === `${task.task.id}_${index}` ? null : `${task.task.id}_${index}`"
            />
          </div>
        </template>
      </template>
      <div v-else class="loading-wrapper">
        <hub-icon name="loading" spin size="lg"></hub-icon>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import Icon from '@/components/common/Icon';
import MilestoneRow from './components/MilestoneRow.vue';
import TaskRow from './components/TaskRow.vue';
import Assignees from '@/components/Assignees';
import DateField from '@/components/common/DateField';
import Button from '@/components/common/Button';
import Multiselect from '@/components/common/Multiselect';

export default {
  components: {
    'hub-icon': Icon,
    MilestoneRow,
    TaskRow,
    Assignees,
    DateField,
    Button,
    Multiselect
    // TaskHistory
  },
  data() {
    return {
      expanded: ['done'],
      includeEarlier: false,
      assignees: [],
      from: new Date(),
      to: new Date(),
      ready: true,
      aggregationField: 'status',
      expandedHistory: null
    };
  },
  computed: {
    ...mapState({
      collection: function (s) {
        return this.aggregationField === 'status' ? s.reports.myTasksCount.collection : s.tasks.personalTaskHistory;
      },
      isGetCollectionRequestPending: function (s) {
        return this.aggregationField === 'status' ? s.reports.myTasksCount.isGetCollectionRequestPending : s.tasks.isGetPersonalTaskHistoryPending;
      },
      email: s => s.identity.email,
      bots: s => s.bots
    }),
    canAddBots() {
      if (!this.bots?.length) {
        return false;
      }

      return this.bots.find(bot => !this.assignees.includes(bot));
    },
    loading() {
      return this.isGetCollectionRequestPending;
    },
    sortedCollection() {
      const result = [];
      if (!this.collection) {
        return result;
      }
      const done = this.collection.find(item => item._id === 'done');
      const created_milestones = this.collection.find(item => item._id === 'created_milestones');
      const created_tasks = this.collection.find(item => item._id === 'created_tasks');

      const statuses = this.collection.filter(item => item._id !== 'done' && item._id !== 'created_milestones' && item._id !== 'created_tasks');
      if (done) {
        result.push(done);
      }
      result.push(...statuses.sort((a, b) => b.tasks.length - a.tasks.length));
      if (created_milestones) {
        result.push(created_milestones);
      }
      if (created_tasks) {
        result.push(created_tasks);
      }
      return result;
    }
  },
  created() {
    this.assignees = [this.email];
  },
  methods: {
    reset() {
      this.ready = false;

      this.assignees = [this.email];
      this.from = new Date();
      this.to = new Date();

      this.ready = true;
      this.aggregationField = 'status';

      this.buildReport();
    },
    fieldChanged(value) {
      this.aggregationField = value;
      this.buildReport();
    },
    fromChanged(value) {
      this.from = value;
      this.buildReport();
    },
    toChanged(value) {
      this.to = value;
      this.buildReport();
    },
    asssigneesChanged(value) {
      this.assignees = [...value];
      this.buildReport();
    },
    async buildReport() {
      if (!this.ready) {
        return;
      }
      this.expandedHistory = null;
      try {
        this.ready = false;
        if (this.aggregationField === 'status') {
          await this.$store.dispatch('reports/myTasksCount/getCollection', {
            assignees: this.assignees,
            from: this.from && this.from.toISOString(),
            to: this.to && this.to.toISOString(),
            includeEarlier: this.includeEarlier,
            aggregationField: this.aggregationField
          });
        } else {
          await this.$store.dispatch('tasks/getPersonalTaskHistory', {
            person: this.assignees,
            from: this.from,
            to: this.to
          });
        }
      } finally {
        this.ready = true;
      }
    },
    async addAllBots() {
      if (this.canAddBots) {
        const notIncludedBots = this.bots.filter(b => !this.assignees.includes(b));
        this.assignees = [...this.assignees, ...notIncludedBots];
        this.buildReport();
      }
    },
    toggleExpanded(id) {
      const index = this.expanded.findIndex(i => i === id);
      if (index > -1) {
        this.expanded.splice(index, 1);
      } else {
        this.expanded.push(id);
      }
    },
    changeIncludeEarlier() {
      this.includeEarlier = !this.includeEarlier;
      this.buildReport();
    }
  }
};
</script>

<style lang="scss" scoped>
.report-wrapper {
  height: 100%;
  display: grid;
  grid-template-rows: max-content 1fr;
  .content {
    overflow-y: scroll;
  }

  .header {
    display: grid;
    background-color: var(--theme-surface);
    padding: 10px;
    padding-top: 0;

    .filters {
      display: grid;
      grid-template-columns: 2fr 0.5fr 0.5fr auto auto;
      align-items: start;
      gap: 10px;
      padding: 10px 0;

      .assignees {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 5px;
        align-items: start;

        .robot-icon {
          margin-top: 15px;
        }
      }

      .reset-button {
        margin-top: 15px;
      }
    }
  }
  .group {
    margin: 5px 0px;
    padding-bottom: 3px;
    background-color: var(--theme-surface);
    border-radius: 2px;
  }
  .task-headers {
    display: grid;
    grid-gap: 10px;
    align-content: center;
    align-items: center;
    justify-content: right;
    grid-template-columns: minmax(250px, 0.2fr) 3fr 1fr 100px 100px;
    min-height: 50px;
    background-color: var(--theme-background);
    font-weight: 600;
    color: var(--theme-on-background);
    border-bottom: 2px solid var(--theme-surface);
  }
  .row {
    font-size: 1rem;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }
  .expanded-report-row {
    padding: 0 5px;
  }
}
.loading-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
