<template>
  <div class="task-history">
    <div ref="streamRootRef" class="task-history" style="overflow-y: scroll">
      <div v-for="message in filteredHistory" :key="message.id">
        <div class="message-wrapper">
          <widget v-bind="message" />
        </div>
      </div>
      <span v-if="!isGetTaskHistoryPending && total > taskHistory.length">opop</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Widget from '@/components/inventions/Widget.vue';

export default {
  components: {
    Widget
  },
  props: {
    person: {
      type: String,
      required: true
    },
    from: {
      type: Date,
      required: true
    },
    to: {
      type: Date,
      required: true
    },
    taskId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      size: 10,
      page: 0
    };
  },
  computed: {
    ...mapState({
      isGetTaskHistoryPending: s => s.tasks.isGetTaskHistoryPending,
      taskHistory: s => s.tasks.taskHistory,
      details: s => s.reports.myInventions.details
    }),
    filteredHistory() {
      return this.taskHistory?.filter(h => h.type === 'task:created' || h.metadata.diffs?.length);
    }
  },
  watch: {
    from() {
      this.loadData();
    },
    to() {
      this.loadData();
    },
    person() {
      this.loadData();
    }
  },
  created() {
    this.loadData();
  },

  methods: {
    async loadData() {
      this.$store.dispatch('tasks/getTaskHistory', {
        person: this.person,
        from: new Date(this.from.setHours(0, 0, 0, 0)),
        to: new Date(this.to.setHours(23, 59, 59, 999)),
        taskId: this.taskId
      });
    },
    async more() {
      this.page++;
      await this.loadData(true);
    }
  }
};
</script>
<style lang="scss" scoped>
.task-history {
  width: 100%;
  height: 100%;
  padding: 0 10px;
}
.no-history-message {
  font-style: italic;
  margin: 3px 0;
}
.message-wrapper {
  margin: 5px 0;
}

.header {
  font-weight: 500;
  font-size: 0.8rem;
  letter-spacing: 0.025em;
  margin-bottom: 5px;
}
.messages-list {
  display: grid;
  grid-gap: 5px;
}
</style>
